import { environment } from "src/environments/environment.prod";
export class Globals {
  public static readonly server = environment.serverUrl;
  public static readonly route = {
    'reset-password': Globals.server + 'api/reset-password',
    'login': Globals.server + 'api/login',
    'verify-otp': Globals.server + 'api/verify-otp',
    'checkemail': Globals.server + 'api/checkemail',
    'sendmail': Globals.server + 'api/sendmail',
    'change-password': Globals.server + 'api/change-password',
    'reset-password-save': Globals.server + 'api/reset-password-save',
    'sowingcropresponse': Globals.server + 'api/listFarmerData',
    'cropType': Globals.server + 'agrioutputmaster/getcroptype',
    // 'getcropname':Globals.server+'agrioutputmaster/getagrioutputcrop',
    'addCrop': Globals.server + 'agrioutputmaster/addagrioutputcrop',
    // 'upload':Globals.server+'farmer/uploadimage',
    'upload': Globals.server + 'upload',
    'getCrop': Globals.server + 'agrioutputmaster/getagrioutputcrop',
    'deleteCrop': Globals.server + 'agrioutputmaster/deleteagrioutputcrop',
    'updateCrop': Globals.server + 'agrioutputmaster/updateagrioutputcrop',
    'getCropById': Globals.server + 'agrioutputmaster/getagrioutputcropbyid',
    'getProfileCount': Globals.server + 'farmer/getfarmervalue',
    'getUserInBatch': Globals.server + 'farmer/regfarmerbatch',
    'getUser': Globals.server + 'farmer/getUser',
    'getUserCount': Globals.server + 'farmer/getUservalue',
    //'getappCount': Globals.server + 'user/getappInstallsValue',
    'getBasicInfo': Globals.server + 'farmer/getBasicInfo',
    'gettotalInfo': Globals.server + 'farmer/gettotalInfo',
    'getIncomeInfo': Globals.server + 'farmer/getIcomeInfo',
    'getBankInfo': Globals.server + 'farmer/getBankInfo',
    'getCropInfo': Globals.server + 'farmer/getcropInfo',
    //dashboard
    'getfarmervalue': Globals.server + 'farmer/getfarmervalue',
    'genderwisefarmer': Globals.server + 'farmer/genderwisefarmer',
    "orderPaymentCount": Globals.server + 'payment/order_payment_count',
    'topFiveProduct': Globals.server + 'agriinputmaster/topfiveproduct',
    'salebymonth': Globals.server + 'agriinputmaster/salebymonth',
    'landareabycrop': Globals.server + 'agriinputmaster/landareabycrop',
    'appdownload': Globals.server + 'user/appdownload',
    'userCount': Globals.server + 'farmer/getUservalue',
    'filterDataByStateMonthYear': Globals.server + 'farmer/statemonthyear',
    'districtwisefarmer': Globals.server + 'farmer/districtwisefarmer',
    'villagewisefarmer': Globals.server + 'farmer/villagewisefarmer',
    'kisanmitrawisefarmer': Globals.server + 'farmer/kisanmitrawisefarmer',
    'updatebyproductcode': Globals.server + 'agriinputmaster/updatebyproductcode',
    'updateInventory': Globals.server + 'agriinputmaster/updateInventory',
    'updateInventoryImages': Globals.server + 'agriinputmaster/updateInventoryImages',
    'agriInfoDetails': Globals.server + 'farmer/agriInfoDetails',
    'getFutureCropInfo': Globals.server + 'farmer/cropFutureInfo',
    'addVariety': Globals.server + 'agrioutputmaster/addvariety',
    'getVariety': Globals.server + 'agrioutputmaster/getvariety',
    'getVarietybyid': Globals.server + 'agrioutputmaster/getvarietybyid',
    'updateVariety': Globals.server + 'agrioutputmaster/updateVariety',
    'deleteVariety': Globals.server + 'agrioutputmaster/deletevariety',
    'getVarietyByLanguage': Globals.server + 'agrioutputmaster/getCropByLanguage',
    'todaybreakup': Globals.server + 'agriinputmaster/todaybreakup',
    'orderbreakup': Globals.server + 'agriinputmaster/orderbreakup',
    'paymentbreakup': Globals.server + 'agriinputmaster/paymentbreakup',
    'addcroptype': Globals.server + 'agrioutputmaster/addcroptype',
    'getcroptype': Globals.server + 'agrioutputmaster/getcroptype',
    'getCroptypebyid': Globals.server + 'agrioutputmaster/getcropbyid',
    'updateCropType': Globals.server + 'agrioutputmaster/updatecroptype',
    'deleteCropType': Globals.server + 'agrioutputmaster/deletecroptype',
    'getCropTypeByLanguage': Globals.server + 'agrioutputmaster/getCropTypeByLanguage',
    'tehsil': Globals.server + 'tehsil',
    //COUNTRY API
    'addCountry': Globals.server + 'country/addcountry',
    'getCountry': Globals.server + 'country/getcountry',
    'updateCountry': Globals.server + 'country/updatecountry',
    'deleteCountry': Globals.server + 'country/deletecountry',
    'checkCountry': Globals.server + 'country/checkcountry',
    'getCountrybyid': Globals.server + 'country/getcountrybyid',
    //state api
    //state API
    'addState': Globals.server + 'state/addstate',
    'getState': Globals.server + 'state/getstate',
    'updateState': Globals.server + 'state/updatestate',
    'checkStateCode': Globals.server + 'state/checkStateCode',
    'getStatebyid': Globals.server + 'state/getstatebyid',
    'deleteState': Globals.server + 'state/deletestate',
    'checkState': Globals.server + 'State/checkState',
    'getStateByCountry': Globals.server + 'State/checkCountrybystate',
    //district
    'addDistrict': Globals.server + 'district/addDistrict',
    'getDistrict': Globals.server + 'district/getDistrict',
    'updateDistrict': Globals.server + 'district/updateDistrict',
    'getDistrictbyid': Globals.server + 'district/getDistrictbyid',
    'deleteDistrict': Globals.server + 'district/deleteDistrict',
    'checkDistrict': Globals.server + 'district/checkDistrict',
    'getDistrictbystate': Globals.server + 'district/getDistrictbystate',
    'checkDistrictCode': Globals.server + 'district/checkDistrictCode',
    ///block
    'block': Globals.server + 'block',
    'blockbyDistrict': Globals.server + 'block/district',
    'checkBlockName': Globals.server + 'block/checkName',
    'checkBlockCode': Globals.server + 'block/checkCode',
    //  //village
    //  'addVillage':Globals.server+'village/addvillage',
    //  'getVillage':Globals.server+'village/getvillage',
    //  'updateVillage':Globals.server+'village/updatevillage/',
    //  'deleteVillage':Globals.server+'village/deletevillage',
    //   //village
    //village
    'addVillage': Globals.server + 'village/addvillage',
    'getVillage': Globals.server + 'village/getvillage',
    'updateVillage': Globals.server + 'village/updatevillage',
    'deleteVillage': Globals.server + 'village/deletevillage',
    'getVillageByBlock': Globals.server + 'village/getVillageByBlock',
    'checkVillage': Globals.server + 'village/checkvillage',
    'checkMCode': Globals.server + 'village/checkMCode',
    'getVillageByCity': Globals.server + 'village/checkvillagebycity',
    //distributor
    'addDistributor': Globals.server + 'master/adddistributor',
    'getDistributor': Globals.server + 'master/getdistributor',
    'updateDistributor': Globals.server + 'master/updatedistributor',
    'deleteDistributor': Globals.server + 'master/deletedistributor',
    'getDistributorByid': Globals.server + 'master/getdistributorbyid',
    //Mandi master
    'addMandi': Globals.server + 'agrioutputmaster/addagrioutputmandi',
    'getMandi': Globals.server + 'agrioutputmaster/getagrioutputmandi',
    'updateMandi': Globals.server + 'agrioutputmaster/updateagrioutputmandi',
    'deleteMandi': Globals.server + 'agrioutputmaster/deleteagrioutputmandi',
    'getMandiss': Globals.server + 'agrioutputmaster/getagrioutputmandibyid',
    'getMandiByLanguage': Globals.server + 'agrioutputmaster/getMandiByLanguage',
    //Produce measuring unit
    'addUnit': Globals.server + 'producemeasure/addproduceunit',
    'getUnit': Globals.server + 'producemeasure/getproduceunit',
    'updateUnit': Globals.server + 'producemeasure/updateproduceunit',
    'deleteUnit': Globals.server + 'producemeasure/deleteproduceunit',
    'getUnitByid': Globals.server + 'producemeasure/getproduceunitbyid',
    //Member ship api
    'addMembership': Globals.server + 'master/addMemberOrganizationType',
    'getMembership': Globals.server + 'master/getMemberOrganizationType',
    'updateMembership': Globals.server + 'master/updateMemberOrganizationType',
    'deleteMembership': Globals.server + 'master/deleteMemberOrganizationType',
    'getMembershipByid': Globals.server + 'master/getMemberOrganizationTypebyid',
    //bank api
    'addBank': Globals.server + 'bankmaster/addbank',
    'getBank': Globals.server + 'bankmaster/getbank',
    'updateBank': Globals.server + 'bankmaster/updatebank',
    'deleteBank': Globals.server + 'bankmaster/deletebank',
    'getBankByid': Globals.server + 'bankmaster/getbankbyid',
    'getBankByIfsc': Globals.server + 'bankmaster/getbankbyifsc',
    //irrigation api
    'addIrrigation': Globals.server + 'master/irrigation',
    'getaddIrrigation': Globals.server + 'master/getirrigation',
    'updateaddIrrigation': Globals.server + 'master/updateirrigation',
    'deleteaddIrrigation': Globals.server + 'master/deleteirrigation',
    'getIrrigationByid': Globals.server + 'master/getIrrigationByid',
    'getqualityparameterbycropid': Globals.server + 'qualityparameter/getqualityparameterbycropid',
    'getAppointmentByFarmerID': Globals.server + 'agrioutputmaster/getAppointmentByFarmerID',
    'addcropsale': Globals.server + 'cropsale/addcropsale',
    'getstaticparameter': Globals.server + 'static/getstaticparameter',
    'updateappointmentstatus': Globals.server + 'agrioutputmaster/updateappointmentstatus',
    //livestock api
    'addLivestock': Globals.server + 'farmer/saveLiveStock',
    'getLivestocks': Globals.server + 'farmer/getLiveStock',
    'updateLivestock': Globals.server + 'farmer/updateLiveStock',
    'deleteLivestock': Globals.server + 'farmer/deleteLiveStocks',
    'getLivestockByid': Globals.server + 'farmer/getLivestockByid',
    //mandirate master api
    'addMandimaster': Globals.server + 'mandimaster/addmandirate',
    'getMandimaster': Globals.server + 'mandimaster/getmandirate',
    'updateMandimaster': Globals.server + 'mandimaster/updatemandirate',
    'deleteMandimaster': Globals.server + 'mandimaster/deletemandirate',
    'getMandimasterss': Globals.server + 'mandimaster/getmandirate',
    //get brand
    'getbrands': Globals.server + 'agriinputmaster/brand',
    //farmer
    'getfamer': Globals.server + 'api/get-farmer',
    'getfarmerbatch': Globals.server + 'api/getfarmerbatch',
    'getFarmerById': Globals.server + 'api/getfarmerById',
    //appointment
    'getAppointment': Globals.server + 'agrioutputmaster/getAppointment',
    'getAppointmentById': Globals.server + 'agrioutputmaster/getAppointmentByID',
    'getAppointmentByCount': Globals.server + 'agrioutputmaster/getappointMentValue',
    //language
    'language': Globals.server + 'master/language',
    'languageByID': Globals.server + 'master/languageByID',
    'checkLanguage': Globals.server + 'master/checkLanguage',
    'getDisrictByPincode': Globals.server + 'farmer/pinNo',
    //kissanmitra
    'addKissanMitra': Globals.server + 'kisanmitra/addkissanmitra',
    'addkissanmtrainmapping': Globals.server + 'kisanmitra/addkissanmitrainmapping',
    'updatekisanmitramapping': Globals.server + 'kisanmitra/updatekisanmitramapping',
    'getKissanMitra': Globals.server + 'kisanmitra/getkisanmitra',
    'updateKissanMitra': Globals.server + 'kisanmitra/addkisanmitraidtofarmer',
    'deleteKissanMitra': Globals.server + 'kisanmitra/deleteKissanmitra',
    'deletekisanmitramap': Globals.server + 'kisanmitra/deletekisanmitramap',
    'addimportcase': Globals.server + 'kisanmitra/adddatatoexcel',
    'udpateKissanmitrawithid': Globals.server + 'kisanmitra/updatekisnamitrabyid',
    'checkemployeeid': Globals.server + 'kisanmitra/checkemployeeid',
    'getkissanmitamapingdata': Globals.server + 'kisanmitra/getkissanmitramappingdata',
    'getkisanmitravalue': Globals.server + 'kisanmitra/getkisanmitravalue',
    'mapKissanMitra': Globals.server + 'kisanmitra/mapkisanmitra',
    'getkisanmitrabypincode': Globals.server + 'kisanmitra/getkisanmitrabypincode',
    'getvillageandrefrelcode': Globals.server + 'kisanmitra/getvillageandrefrelcode',
    //  'checkrefrelcodebyvillage':Globals.server+'kisanmitra/getrefrelcodebehalfofvillage',
    'getkisanmitrabyvillage': Globals.server + 'kisanmitra/getrefrelcodebehalfofvillage',
    'getstateanddistrict': Globals.server + 'kisanmitra/getstateanddistrict',
    //  'deleteKissanMitra':Globals.server+'kisanmitra/deleteKissanmitra',
    ////////////////////////order api///////////////////////////////////////
    'getorder': Globals.server + 'agriinputmaster/order',
    'orderCounts': Globals.server + 'agriinputmaster/orderCounts',
    'orderCSV': Globals.server + 'agriinputmaster/order-csv',
    'yearlyOrders': Globals.server + 'agriinputmaster/yearOrders',
    'orderbyid': Globals.server + 'agriinputmaster/orderbyid',
    'getTaxById': Globals.server + 'agriinputmaster/getTaxById',
    'updateOrder': Globals.server + 'agriinputmaster/updateOrder',
    ////////////////////////add payment api///////////////////////////////////////
    'payment': Globals.server + 'payment/payment',
    'paymentbyorder': Globals.server + 'payment/paymentbyorder',
    'checkpayment': Globals.server + 'payment/checkpayment',
    ////////////////////////add delivery api///////////////////////////////////////
    'delivery': Globals.server + 'delivery/delivery',
    'deliverybyorder': Globals.server + 'delivery/deliverybyorder',
    'updatedelivery': Globals.server + 'delivery/updatedelivery',
    ////////////////////////////// center manager start ////////////////////////////
    'getqualityparameter': Globals.server + 'qualityparameter/getqualityparameter',
    'addqualityparameter': Globals.server + 'qualityparameter/addqualityparameter',
    'getqualityparameterbyid': Globals.server + 'qualityparameter/getqualityparameterbyid',
    'updatequalityparameter': Globals.server + 'qualityparameter/updatequalityparameter',
    'deletequalityparameter': Globals.server + 'qualityparameter/deletequalityparameter',
    'addUser': Globals.server + 'user/signUp',
    'addcenter': Globals.server + 'center/addcenter',
    'getcenter': Globals.server + 'center/getcenter',
    'deletecenter': Globals.server + 'center/deletecenter',
    'getcenterbyid': Globals.server + 'center/getcenterbyid',
    'updatecenter': Globals.server + 'center/updatecenter',
    //
    'getAllCenterManager': Globals.server + 'user/centermanager',
    'getuserbyid': Globals.server + 'user/getuser',
    'checkMobile': Globals.server + 'user/checkMobile',
    'checkEmail': Globals.server + 'user/checkEmail',
    'userbyemail': Globals.server + 'user/userbyemail',
    ////////////////////////////// center manager end ////////////////////////////
    ///////////////////////////////roles and permission start///////////////////////////
    'addRolesAndPermissions': Globals.server + 'roles_permission/addroles_permission',
    'getRolesAndPermissions': Globals.server + 'roles_permission/getroles_permission',
    'getroles_permissionbyid': Globals.server + 'roles_permission/getroles_permissionbyid',
    'updateroles_permission': Globals.server + 'roles_permission/updateroles_permission',
    'deleteroles_permission': Globals.server + 'roles_permission/deleteroles_permission',
    //////////////////////////// roles and permission end /////////////////////////////
    //////////////////////////// survey start /////////////////////////////
    'survey_responses': Globals.server + 'sowingCropRes/all-survey-response',
    'maize_responses': Globals.server + 'sowingCropRes/maize-survey-response',
    'monthlyExport': Globals.server + 'sowingCropRes/getSurveyCSV',
    //////////////////////////// survey end /////////////////////////////
    //////////////////////////// bv quick /////////////////////////////
    'deliveryPerson': Globals.server + 'quick-delivery/delivery-person',
    'activeDeliveryPerson': Globals.server + 'quick-delivery/active-delivery-person',
    'villageForQuick': Globals.server + 'agriinputmaster/all-villages',
    'saveQuickVill': Globals.server + 'agriinputmaster/quick-villages/add',
    'quickVill': Globals.server + 'agriinputmaster/quick-villages',
    //////////////////////////// survey end /////////////////////////////

    /////////////////////////// dark stores and warehouses //////////////
    'darkstore':  Globals.server + 'agriinputmaster/dark-stores',
    'warehouse':  Globals.server + 'agriinputmaster/warehouse',
  };
  static Purchase: any;
  static Membership: any;
}
export class Purchase {
  public static readonly server = environment.serverUrl;
  public static readonly route = {
    'uploads': Purchase.server + 'farmer/uploadsimage',
    'uploadsingleimages': Purchase.server + 'farmer/uploadimage',
    'productCategory': Purchase.server + 'agriinputmaster/productCategory',
    'productCategoryByID': Purchase.server + 'agriinputmaster/getproductcategoriesbyid',
    'productSubCategory': Purchase.server + 'agriinputmaster/productSubCategory',
    'getsubproductcategorybyid': Purchase.server + 'agriinputmaster/getsubproductcategorybyid',
    'taxRate': Purchase.server + 'agriinputmaster/taxRate',
    'taxRateByID': Purchase.server + 'agriinputmaster/getTaxById',
    'checkTaxCode': Purchase.server + 'agriinputmaster/checkTaxCode',
    'brand': Purchase.server + 'agriinputmaster/brand',
    'brandByID': Purchase.server + 'agriinputmaster/brandByID',
    'checkBrandCode': Purchase.server + 'agriinputmaster/checkBrandCode',
    'unit': Purchase.server + 'agriinputmaster/unit',
    'unitByID': Purchase.server + 'agriinputmaster/unitByID',
    'checkUnitCode': Purchase.server + 'agriinputmaster/checkUnitCode',
    'addProduct': Purchase.server + 'agriinputmaster/addproduct',
    'getproduct': Purchase.server + 'agriinputmaster/getproduct',
    'productdetailsbyid': Purchase.server + 'agriinputmaster/productdetailsbyid',
    'updateproduct': Purchase.server + 'agriinputmaster/updateproduct',
    'checkproductcode': Purchase.server + 'agriinputmaster/checkproductcode',
    'getProductByCode': Purchase.server + 'agriinputmaster/getProductByCode',
    'deleteproduct': Purchase.server + 'agriinputmaster/deleteproduct',
    'getprodbycode': Purchase.server + 'agriinputmaster/getprodbycode',
  };


}
export class Membership {
  public static readonly server = environment.serverUrl;
  public static readonly route = {
    'membership': Membership.server + 'agriinputmaster/memberships',
    'members': Membership.server + 'agriinputmaster/members',
    'addmembership': Membership.server + 'agriinputmaster/add-membership',
  };
}
